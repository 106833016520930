<template>
  <CommonLayout>
    <div class="cabinet">
      <div class="cabinet__inner">
        <button
          class="button-default button-back uni-back"
          @click="$router.go(-1)"
        >
          <span></span>Назад
        </button>
        <div class="cabinet__head">
          <!-- <router-link
            class="button-default button-back cabinet__back"
            :to="{ name: 'rating', params: { type: 'team' } }"
            ><span></span>Назад</router-link
          > -->
          <div class="title">Участники команды “{{ getTeam.teamName }}”</div>
        </div>
        <div class="cabinet__body cabinet__body_type_rationg">
          <div class="cabinet__content">
            <div class="filter">
              <div class="filter__title">
                <div class="uni-search">
                  <input
                    type="text"
                    v-model="searchTeam"
                    placeholder="Поиск по ФИО"
                  />
                </div>
              </div>
              <div class="filter__body">
                <div class="filter__caption">Сортировать по:</div>
                <div class="filter__controls">
                  <button
                    v-for="(item, index) in sortOptions"
                    :key="index"
                    class="filter__button"
                    :class="{
                      active: item.active,
                      reverse: item.orderBy === 'desc',
                    }"
                    @click="handleSort(index, item.orderBy)"
                  >
                    {{ item.name }}
                  </button>
                </div>
              </div>
            </div>
            <div class="cabinet-table">
              <Loading v-if="loading" position="center" />
              <div v-else class="cabinet-table__inner">
                <table v-if="limitedList.length === 0">
                  <tbody>
                    <!-- <tr class="disabled empty">
                      <td>Пусто</td>
                    </tr> -->
                  </tbody>
                </table>
                <table v-else>
                  <thead>
                    <tr>
                      <th>ФИО</th>
                      <th width="140px">Достижения</th>
                      <th width="240px">
                        <div
                          class="uni-switcher uni-switcher_type_mini cabinet-table-switcher"
                          :class="{ active: isActiveTotal }"
                        >
                          <button
                            class="uni-switcher__button"
                            @click="isActiveTotal = !isActiveTotal"
                          >
                            За игру
                          </button>
                          <button
                            class="uni-switcher__button"
                            @click="isActiveTotal = !isActiveTotal"
                          >
                            На планете
                          </button>
                          <Tooltip
                            class="tooltip_style_dark"
                            :msg="`
                              <table>
                                <tr><td>За игру</td><td>Количество GPB-coins, полученных за <br/>все время</td></tr>
                                <tr><td>На планете</td><td>Отображаются GPB-coins, полученные на <br/>текущей планете. Рейтинг обновляется <br/>еженедельно, данные при переходе на <br/>новую планету будут доступны со второй <br/>недели</td></tr>
                              </table>
                            `"
                          />
                        </div>
                      </th>
                      <th width="100px">Место</th>
                    </tr>
                  </thead>
                  <tbody v-if="captain.edit">
                    <tr
                      v-for="(item, index) in limitedList"
                      :key="index"
                      @click="choose(index)"
                      :class="{
                        disabled: isDisabled(captain.selected[index]),
                        selected: captain.selected[index],
                      }"
                    >
                      <td>
                        <div class="checkbox">
                          <input
                            v-model="captain.selected[index]"
                            type="checkbox"
                          />
                          <span></span>
                        </div>
                        {{ item.playerName }}
                      </td>
                      <td>
                        {{ getTeam.calculatedCoinsCurrentIsland }}
                      </td>
                      <td>{{ item.playerCoins }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <router-link
                      v-for="(item, index) in limitedList"
                      :key="index"
                      tag="tr"
                      :class="{ myself: item.id === getProfile.id }"
                      :to="linkToProfile(item.id)"
                    >
                      <td>{{ item.playerName }}</td>
                      <td>
                        <div v-if="item.achievements.length" class="uni-achive">
                          <router-link
                            tag="div"
                            :to="linkToProfile(item.id, 'achievements')"
                            v-for="(achieve, index) in item.achievements"
                            :key="index"
                            class="uni-achive__item"
                            :style="{
                              'background-image': `url('/static/achieves/${achieve.icon}')`,
                            }"
                          >
                            <Tooltip
                              type="rating"
                              :msg="`
                              <p><strong>${achieve.title}</strong></p>
                              <p>${achieve.description}</p>`"
                            />
                          </router-link>
                        </div>
                        <div v-else class="uni-achive-empty"></div>
                      </td>
                      <td>
                        <div class="uni-coins uni-coins_type_table">
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />{{
                            isActiveTotal
                              ? item.playerCoins
                              : weekCoinsFilter(
                                  item.calculatedCoinsCurrentIsland
                                )
                          }}
                        </div>
                      </td>
                      <td>
                        {{
                          isActiveTotal ? item.ratingTotal : item.ratingIsland
                        }}
                      </td>
                    </router-link>
                  </tbody>
                </table>
                <div class="cabinet-table__more">
                  <button
                    v-if="
                      !searchTeam && limit && limit < getTeam.teamPlayers.length
                    "
                    @click="limit = null"
                  >
                    Смотреть все
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="cabinet__sidebar">
            <Dashboard :view="typeDashboard" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="captain.show" class="captain">
      <div class="captain__inner">
        <div class="captain__head">
          <div class="captain__text">Доступно</div>
          <div class="captain__number">{{ captain.availableAmount }}</div>
          <div class="captain__text">{{ captainText }}</div>
        </div>
        <div class="captain__body">
          <div v-if="captain.edit" class="captain__controls">
            <div class="captain__caption">Выбрано: {{ selectedLength }}</div>
            <button @click="submit" class="button-default captain__button">
              Применить
            </button>
            <button
              @click="reset"
              class="button-default button-secondary captain__button"
            >
              Отмена
            </button>
          </div>
          <div v-else class="captain__controls">
            <button
              :disabled="!captain.availableAmount"
              @click="captain.edit = true"
              class="button-default captain__button"
            >
              Назначить задание
            </button>
          </div>
        </div>
      </div>
    </div> -->
  </CommonLayout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout.vue";
import Dashboard from "@/components/Dashboard.vue";
import { mapGetters, mapActions } from "vuex";
import { sorting, declOfNum } from "@/utils";
import { GetCaptainQuestsCount, CaptainSetQuests } from "@/api/PlayerQuests";
import Tooltip from "@/components/Tooltip.vue";

export default {
  props: ["teamID"],
  components: {
    CommonLayout,
    Dashboard,
    Tooltip,
  },
  data() {
    return {
      captain: {
        show: false,
        availableAmount: 0,
        edit: false,
        selected: [],
      },
      limit: 7,
      list: [],
      searchTeam: "",
      sortOptions: [
        {
          name: "Имени А-Я",
          field: "playerName",
          active: false,
          orderBy: "asc",
        },
        {
          name: "Рейтингу",
          field: "playerCoins",
          active: true,
          orderBy: "desc",
        },
      ],
      isActiveTotal: true,
    };
  },
  computed: {
    ...mapGetters(["profileStatus", "getProfile", "teamStatus", "getTeam"]),
    loading() {
      return this.teamStatus === "loading";
    },
    limitedList() {
      if (this.searchTeam) {
        return this.list
          .filter((item) => {
            return item.playerName
              .toLowerCase()
              .includes(this.searchTeam.toLowerCase());
          })
          .slice(0, this.limit);
      }

      return this.limit ? this.list.slice(0, this.limit) : this.list;
    },
    selectedLength() {
      return this.captain.selected.filter((item) => item === true).length;
    },
    typeDashboard() {
      if (this.teamID === this.getProfile.teamId) {
        return "ratingMyTeam";
      }

      return "ratingMyTeam";
    },
    captainText() {
      return declOfNum(this.captain.availableAmount, [
        "задание",
        "задания",
        "заданий",
      ]);
    },
  },
  created() {
    this.isCaptain();
    this.TEAM_FETCH(this.teamID).then(
      () => (this.list = this.getTeam.teamPlayers)
    );
  },
  methods: {
    ...mapActions(["TEAM_FETCH"]),
    linkToProfile(userID, name) {
      // if (userID === this.getProfile.id) {
      //   return { name: 'profile' };
      // }
      if (name) {
        return { name: "achievements", params: { userID } };
      }

      return {
        name: "user",
        params: { userID: userID },
        query: { team: this.teamID },
      };
    },
    handleSort(index, orderBy) {
      if (this.sortOptions[index].active) {
        this.sortOptions[index].orderBy = orderBy === "asc" ? "desc" : "asc";
      } else {
        this.sortOptions.forEach((item) => {
          item.active = false;
        });
        this.sortOptions[index].active = true;
      }

      this.list = sorting(this.list, this.sortOptions, index);
    },
    isCaptain() {
      if (
        this.getProfile &&
        this.getProfile.isCaptain &&
        this.getProfile.teamId === Number(this.teamID)
      ) {
        GetCaptainQuestsCount().then((resp) => {
          this.captain.show = true;
          this.captain.availableAmount = resp.data;
        });
      }
    },
    isDisabled(selected) {
      if (!selected && this.selectedLength >= this.captain.availableAmount) {
        return true;
      }
    },
    choose(index) {
      if (
        !this.captain.selected[index] &&
        this.selectedLength >= this.captain.availableAmount
      ) {
        this.$set(this.captain.selected, index, false);
        return;
      }
      this.$set(this.captain.selected, index, !this.captain.selected[index]);
    },
    reset() {
      this.captain.edit = false;
      this.captain.selected = [];
    },
    submit() {
      let arr = [];
      let taskFormatter;
      let directFormatter;
      let memeberFormatter;

      this.captain.selected.forEach((item, index) => {
        if (item) {
          arr.push(this.getTeam.teamPlayers[index].id);
        }
      });

      if (!arr.length) {
        this.$modal.show("message", {
          message: `Выберите минимум одного участника!`,
        });
        return;
      }

      taskFormatter = declOfNum(arr.length, ["задание", "задания", "заданий"]);
      directFormatter = declOfNum(arr.length, [
        "направлено",
        "направлены",
        "направлены",
      ]);
      memeberFormatter = declOfNum(arr.length, [
        "участнику",
        "участникам",
        "участникам",
      ]);

      CaptainSetQuests({ userIds: arr })
        .then(() => {
          this.$modal.show("message", {
            message: `${arr.length} ${taskFormatter} успешно ${directFormatter} ${memeberFormatter}!`,
          });
          this.reset();
          this.isCaptain();
        })
        .catch((err) => {
          this.$modal.show("message", {
            message: err ? err.data : "Произошла ошибка. Попробуйте позже!",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cabinet-table {
  td,
  th {
    &:nth-child(3) {
      text-align: center;
    }
    &:nth-child(4) {
      text-align: center;
    }
  }
}

.uni-search {
  margin-top: 0;
  margin-bottom: 0;
}
.filter {
  // margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;

  color: #76a6a2;
  width: 800px;
  &__title {
  }
  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__caption {
    font-size: 16px;
  }
  &__controls {
  }
  &__button {
    margin-left: 5px;
    font-size: 16px;
    color: #76a6a2;
    text-decoration-line: underline;
    border: none;
    background: none;
    position: relative;
    padding-right: 16px;
    &:hover {
      text-decoration: none;
    }
    &:before {
      position: absolute;
      top: 3px;
      right: 0px;
      margin-left: 5px;
      width: 10px;
      height: 10px;
      border-bottom: 1px solid #76a6a2;
      border-right: 1px solid #76a6a2;
      content: "";
      transform: rotate(45deg);
    }
    &.active {
      font-weight: bold;
      &:before {
        border-bottom-width: 2px;
        border-right-width: 2px;
      }
    }
    &.reverse {
      &:before {
        top: 9px;
        transform: rotate(225deg);
      }
    }
  }
}

.captain {
  position: absolute;
  top: 45px;
  left: 50%;
  // margin-left: 395px;
  margin-left: 380px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &__inner {
    width: 221px;
  }
  &__head {
    background-image: url("data:image/svg+xml,%3Csvg width='221' height='127' viewBox='0 0 221 127' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M220.932 0H209v4.1715h7.815V122.365H209V127h11.932V0z' fill='%230FF'/%3E%3Cpath opacity='.7' fill='%2300FFFE' fill-opacity='.15' d='M1 0h220v127H1z'/%3E%3Cpath d='M0 127h11.9315v-4.172H4.1167V4.635h7.8148V0H0v127z' fill='%230FF'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    height: 127px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 6px;
  }
  &__text {
    font-size: 24px;
    color: #5cffed;
  }
  &__number {
    font-size: 30px;
    font-weight: bold;
    margin-top: 3px;
  }
  &__body {
    margin-top: 30px;
  }
  &__caption {
    font-size: 20px;
    color: #5cffed;
    margin-bottom: 25px;
  }
  &__button {
    width: 100%;
    margin-bottom: 20px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.myself {
  background-image: url("~@/assets/img/icon/star-white.svg");
  background-repeat: no-repeat;
  background-position: 7px calc(50% + 1px);
}
</style>
